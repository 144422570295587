import {useState, useMemo} from 'react';
import Lottie from "react-lottie";

function LottieWrapper({json, width, height, className, speed, loop, alwaysVisible}) {
    const [visible, setVisible] = useState(true);

    const defaultOptions = useMemo(() => {
        return {
            loop: loop,
            autoplay: true,
            animationData: json,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
    }, [loop, json]);

    const handleVisibilityChange = (visible) => {
        setVisible(alwaysVisible || visible);
    }

    return (
        <div className={className}>
            {visible ?
                <Lottie
                    speed={speed}
                    options={defaultOptions}
                    width={width}
                    height={height}
                />
                : <div style={{height: '300px', width: '100%'}}/>
            }
        </div>
    )
}

export default LottieWrapper
