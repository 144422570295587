import React, {Component} from "react";
import moment from "moment";
import ChallengeDonationBar from "../components/ChallengeDonationBar";
import ChallengeAwarenessBar from "../components/ChallengeAwarenessBar";
import DownloadAppCTA from "./Main/DownloadAppCTA";
import SvgMoney from "../components/icons/SvgMoney";
import {getChallenge, getChallengeActions} from "../api/challenges";
import {wait} from "../utils/timestamp";
import SvgCopy from "../components/icons/SvgCopy";
import SvgCheck from "../components/icons/SvgCheck";
import SvgFire from "../components/icons/SvgFire";
import {withRouter} from "react-router-dom";
import SvgPiggy from "../components/icons/SvgPiggy";
import {formatStatistics} from "../utils/helpers";
import SvgMegaphone from "../components/icons/SvgMegaphone";
import ChallengeEndDate from "../components/ChallengeEndDate";
import SvgArrowForward from "../components/icons/ArrowForward";
import {connect} from "react-redux";
import LoadingSection from "../components/LoadingSection";
import {CARD_STYLE_NO_HOVER} from "../utils/dummy";
import {Button} from "../components/atoms/Button";
import NPOPicture from "../components/atoms/NPOPicture";
import DonationCard from "../components/DonationCard";
import AwarenessCard from "../components/AwarenessCard";
import PledgeCard from "../components/PledgeCard";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        mobile: state.userReducer.mobile,
    }
}

class PublicChallengePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            challenge: {},
            nonProfit: {},
            copied: false,
            loading: true,
            actions: [],
        }
    }

    componentDidMount = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const challengeId = lastArg.split("?")[0];

        const challenge = await getChallenge(challengeId);
        const actions = await getChallengeActions(challengeId);

        this.setState({
            challenge: challenge.challenge,
            nonProfit: challenge.challenge.non_profit,
            actions: actions.actions,
            loading: false
        });
    }

    copyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        this.setState({copied: true});
        wait(2000).then(() =>
            this.setState({copied: false})
        )
    }

    toAuth = () => {
        this.props.history.push({pathname: `/authentication`, state: {previous: "Back"}})
    }

    render() {
        const challenge = this.state.challenge || {};
        const expired = new Date() > new Date(challenge.end_date);
        const nonProfit = this.state.nonProfit || {};
        const matching = challenge.donation_match || 0;
        const challengeEndDate = moment(challenge.end_date);
        const currentDate = moment();
        const inPast = challengeEndDate.isBefore(currentDate);


        return (
            <div className='col-plain page-wrapper col-ac'
                 style={{backgroundColor: "rgba(150,204,248,0.13)", paddingTop: 120}}>
                <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1, opacity: .3}}/>
                <div className='flex flex-col flex-1 overflow-hidden'>
                    {this.state.loading ?
                        <LoadingSection/>
                        :
                        <div className='p-5 flex flex-col flex-1 overflow-y-scroll bg-white' style={{maxWidth: this.props.mobile ? null : '60vw', minWidth: this.props.mobile ? null : '60vw'}}>
                            <div className={`${CARD_STYLE_NO_HOVER}`}>
                                <img src={challenge.cover_image}
                                    style={{width: '100%', height: this.props.mobile ? 120 : 280, objectFit: 'cover'}}
                                    className='br-8'/>
                                <div className='row-jb mt-8'>
                                    <div>
                                        <p className='heading4 mt-12'>
                                            {challenge.challenge_name}
                                        </p>
                                        <div className='row-ac body2 black-50 mb-8 mt-4'>
                                            <p>
                                                Created by
                                            </p>
                                            <img
                                                src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${challenge.creator}.jpeg?alt=media`}
                                                style={{width: 20, height: 20}}
                                                className='br-32 mh-4'/>
                                            <p>
                                                {challenge.creator_name}
                                            </p>
                                        </div>
                                    </div>
                                    <ChallengeEndDate challenge={challenge}/>
                                </div>
                                <div className='br-12 p-2 mb-12 row-ac npo-nav-card pointer'
                                    style={{marginRight: 'auto'}}
                                    onClick={(e) => {
                                        this.toAuth()
                                        e.stopPropagation()
                                    }
                                    }
                                >
                                    <NPOPicture
                                        size={40}
                                        np={nonProfit}
                                        logoUrl={nonProfit.logo_url}
                                        className={'mr-3'}
                                    />
                                    <div className='col-plain'>
                                        <p className='body3 black-50'>
                                            A challenge for
                                        </p>
                                        <p className='body2 black-100'>
                                            {nonProfit.name}
                                        </p>
                                    </div>
                                    <SvgArrowForward className={'primary ml-12'}/>
                                </div>
                                <p className='body2 black-100 mb-24'>
                                    {challenge.description}
                                </p>
                                {inPast ?
                                    <div className='mb-12 p-4 br-12' style={{backgroundColor: '#0088ff30'}}>
                                        <h2 className='primary title2 m-0'>Challenge Ended!</h2>
                                        {!!challenge.donations_progress &&
                                            <div className='row-ac pt-12 primary'>
                                                <SvgPiggy style={{width: 20, height: 20}}/>
                                                <p className='ml-8 body2-bold'>
                                                    {`$${formatStatistics(challenge.donations_progress, 1)}`} raised
                                                </p>
                                            </div>
                                        }
                                        {!!challenge.awareness_progress &&
                                            <div className='row-ac pt-12 primary'>
                                                <SvgMegaphone style={{width: 20, height: 20}}/>
                                                <p className='ml-8 body2-bold'>
                                                    This cause gained {challenge.awareness_progress} new followers
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <>
                                        {challenge.donations_goal > 0 ?
                                            <div className='mb-12'>
                                                <ChallengeDonationBar challenge={challenge} matching={matching}/>
                                            </div>
                                            : null}
                                        {challenge.awareness_goal > 0 ?
                                            <div>
                                                <ChallengeAwarenessBar challenge={challenge} matching={matching}/>
                                            </div>
                                            : null}
                                    </>
                                }
                                <div className='flex flex-row gap-3 mt-24'>
                                    <Button variant={'secondary'} radius={'full'} className='flex-1'
                                            onClick={this.copyLink}>
                                        {this.state.copied ?
                                            <SvgCheck style={{width: 18, height: 18}} className={'mr-4'}/>
                                            :
                                            <SvgCopy style={{width: 18, height: 18}} className={'mr-4'}/>
                                        }
                                        {this.state.copied ? "Link copied" : "Invite a friend"}
                                    </Button>
                                    {challenge.donations_goal > 0 && !expired ?
                                        <Button onClick={this.toAuth} variant={'default'} radius={'full'}
                                                className='flex-1'
                                                style={{backgroundColor: matching > 0 ? '#F8969C' : null}}>
                                            {matching > 0 ?
                                                <SvgFire style={{width: 20, height: 20, marginBottom: 1}}
                                                        className={'mr-4'}/>
                                                :
                                                <SvgMoney style={{width: 20, height: 20, marginBottom: 1}}
                                                        className={'mr-4'}/>
                                            }
                                            Donate {matching > 0 ? ' (2x)' : null}
                                        </Button>
                                        : null}
                                </div>
                                {matching > 0 ?
                                    <div className='row-ac p-2 br-8 mt-12'
                                        style={{backgroundColor: "#F8969C20", color: '#F8969C'}}>
                                        <SvgFire style={{width: 20, height: 20, minWidth: 20}}/>
                                        <p className='body2 ml-12'>
                                            You've found a challenge with matching! This means that for every dollar you
                                            contribute,
                                            a matching dollar with be donated by Starfish / our partners. Just one more way
                                            to
                                            make
                                            an even bigger impact.
                                        </p>
                                    </div>
                                    : null
                                }
                            </div>
                            <>
                                <p className='black-100 text-xl font-semibold mt-5 mb-3'>
                                    Challenge Activity
                                </p>
                                {this.state.actions.length === 0 &&
                                    <div className='col-ac-jc pv-24'>
                                        <p className='body2 black-50'>
                                            No activity yet.
                                        </p>
                                    </div>
                                }
                                {this.state.actions.map(action => {
                                    if (action.type === 'donations') {
                                        return (
                                            <DonationCard action={action}/>
                                        )
                                    } else if (action.type === 'awareness') {
                                        return (
                                            <AwarenessCard action={action}/>
                                        )
                                    }
                                    return (
                                        <PledgeCard action={action} />
                                    )
                                })}
                            </>
                            <DownloadAppCTA mobile={this.props.mobile} ctaText={"Participate in the challenge"}/>

                        </div>
                    }
                </div>
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps)(PublicChallengePage));
