import React, {Component} from "react";
import AuthModal from "../components/AuthModal";
import {withRouter} from "react-router-dom";
import {formatStatistics} from "../utils/helpers";
import {getCampaign} from "../api/campaigns";
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class CampaignPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            challenges: [],
            authModalOpen: false,
            loading: false,
            campaign: {},
        }
    }

    componentDidMount = async () => {
        this.setState({loading: true})
        const res = await getCampaign("KcY0JCJ4oamrAhCeFFMW");
        const campaign = res.campaign;
        this.setState({campaign, challenges: campaign.challenges, loading: false});
    }

    closeAuthModal = () => {
        this.setState({authModalOpen: false})
    }

    navigateToChallenge = (id) => {
        this.props.history.push({pathname: `/challenge/${id}`, state: {previous: "Campaign"}})
    }

    render() {
        const campaign = this.state.campaign || {};
        const challenges = campaign.challenges || [];
        if (this.state.loading) {
            return (
                <div className='col-ac-jc' style={{width: '100vw', height: '100vh'}}>
                    <img src={require('../img/brand/logo.png')} className='logo-spin'/>
                </div>
            )
        }
        return (
            <div className='col-plain page-wrapper col-ac pb-32'
                 style={{backgroundColor: "rgba(150,204,248,0.13)", minHeight: '100vh', paddingTop: 160}}>
                <div className='absolute cover-parent hero-wrapper' style={{zIndex: -1, opacity: .3}}/>
                <div
                    className=' col-plain br-12 p-4 mb-12'
                    style={{
                        boxShadow: '0 4px 12px #00000030',
                        width: this.props.mobile ? '90vw' : 800,
                        border: '2px solid #054A91FF',
                        backgroundColor: 'white',
                        boxSizing: 'border-box'
                    }}>
                    <img src={campaign.cover_image} style={{backgroundColor: "white"}}/>
                    <div className='row-ac-jb'>
                        <div className='col-plain flex-1'>
                            <p className='heading2 mt-24'>
                                {campaign.campaign_name}
                            </p>
                            <p className='body2 black-80 mt-8'>
                                {campaign.description}
                            </p>
                        </div>
                        {!this.props.mobile ?
                            <div style={{width: 140}} className='mr-24 col-ac-jc'>
                                <CircularProgressbar
                                    value={100 * (campaign.donations_progress / campaign.donations_goal)}
                                    text={"$" + formatStatistics(campaign.donations_progress)}
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathTransitionDuration: 0.5,
                                        pathColor: `#0088ff`,
                                        trailColor: '#d6d6d6',
                                        textColor: `#0088ff`,
                                    })}
                                />
                                <p className='mt-12 body3-bold text-center'>
                                    Total Amount<br/>Raised
                                </p>
                            </div>
                            : null}
                    </div>
                    <div className='row-ac-jc wrap pt-24' style={{gap: 20}}>
                        <div className='mb-12 p-16 br-12'
                             style={{backgroundColor: '#0088ff30', flex: 1, color: '#0088ff'}}>
                            <h2 className=' title2 m-0'>We reached our goal!</h2>
                            <div className='col-plain body2 mt-8'>
                                <p>
                                    Thanks for your support! With the help of the Starfish community, $1,007,584 was
                                    donated to participating
                                    causes. Your impact is greatly appreciated. Stay tuned for more ways to get involved
                                    and grow your impact.
                                </p>
                            </div>
                        </div>
                        {this.props.mobile ?
                            <div style={{width: 140}} className='col-ac-jc'>
                                <CircularProgressbar
                                    value={100 * (campaign.donations_progress / campaign.donations_goal)}
                                    text={"$" + formatStatistics(campaign.donations_progress)}
                                    styles={buildStyles({
                                        strokeLinecap: 'butt',
                                        pathTransitionDuration: 0.5,
                                        pathColor: `#0088ff`,
                                        trailColor: '#d6d6d6',
                                        textColor: `#0088ff`,
                                    })}
                                />
                                <p className='mt-12 body3-bold text-center'>
                                    Total Amount<br/>Raised
                                </p>
                            </div>
                            : null
                        }
                    </div>
                </div>
                <div className='row-ac-jc mb-24' style={{width: this.props.mobile ? '80vw' : 800}}>
                    <p className='title1 mt-16'>
                        Participating Causes:
                    </p>
                </div>
                <div className='' style={{
                    width: this.props.mobile ? '90vw' : 800,
                    display: 'grid',
                    gridTemplateColumns: this.props.mobile ? '1fr' : '1fr 1fr',
                    columnGap: '8px',
                    boxSizing: 'border-box'
                }}>
                    {challenges.map(item => {
                            return (
                                <button className='feed-card col-plain br-12 p-5 pv-12 mb-12 row-ac'
                                        key={item.challenge_id}
                                        onClick={() => this.navigateToChallenge(item.challenge_id)}
                                        style={{
                                            gap: 8,
                                            boxShadow: '0 4px 12px #00000030',
                                            border: '2px solid #054A91FF',
                                            boxSizing: 'border-box'
                                        }}>
                                    <img src={item.non_profit.logo_url}
                                         style={{
                                             width: 64,
                                             height: 64,
                                             border: '1px solid #00000020',
                                             objectFit: "contain"
                                         }}
                                         onError={(e) => {
                                             e.target.src =
                                                 "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                                         }}
                                         className='br-12 mb-8'/>
                                    <div style={{display: 'flex', flex: 1, alignItems: "flex-start", textAlign: 'left'}}
                                         className='col-plain'>
                                        <p className='body1-bold mb-4'>
                                            {item.non_profit.name}
                                        </p>
                                        <p className='body2 black-50'>
                                            {item.non_profit.category}
                                        </p>
                                    </div>
                                    <div style={{width: 80}} className='col-ac'>
                                        <CircularProgressbar
                                            value={100 * (item.donations_progress / item.donations_goal)}
                                            // value={64}
                                            strokeWidth={12}
                                            text={"$" + formatStatistics(item.donations_progress)}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                textSize: '16px',
                                                pathTransitionDuration: 0.5,
                                                pathColor: `#0088ff`,
                                                textColor: `#0088ff`,
                                                trailColor: '#d6d6d6',
                                            })}
                                        />
                                    </div>
                                </button>

                            )
                        }
                    )}
                </div>
                <AuthModal
                    isOpen={this.state.authModalOpen}
                    closeModal={this.closeAuthModal}
                    mobile={this.props.mobile}
                    createCheckout={() => null}
                />
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps)(CampaignPage));
